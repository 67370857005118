// CSS Imports
import '/src/css/style.pcss';

// NPM Imports
import Alpine from 'alpinejs'

window.Alpine = Alpine
Alpine.start()


//COMPONENTS IMPORTS
// import swiperProjects from './components/swiper-projects.example';
// import gallery from './components/gallery.example';
//
// //INIT COMPONENTS
// swiperProjects();
// gallery();
